<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Добавить ТС в договор {{ item.vccarriercontractsDocnum }}
        </v-toolbar-title>
        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="onClose"
          >
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-4">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Vehicles
              :items="vehicles"
              :loadingConfirm="loadingConfirm"
              :loadingRefs="loadingRefs"
              :routes="routes"
              :compositions="compositions"
              @onCancel="cancelAdd"
              @onConfirm="confirmAdd"
              @onAdd="showAllVehicles"
              @onRemove="removeVehicle"
              @composition="getCurrentComposition"
              @route="getCurrentRoute"
            ></Vehicles>
          </v-tab-item>

          <v-tab-item>
            <AllVehicles
              :items="allVehicles"
              :loading="loadingAll"
              :loadingRefs="loadingRefs"
              :vehicles="vehicles"
              @onCancel="cancelAdd"
              @onAdd="onAdd"
            ></AllVehicles>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>

  </v-dialog>
</template>

<script>
  import Vehicles from "./components/Vehicles";
  import AllVehicles from "./components/AllVehicles";
  import GeneralServices from '@/services/GeneralServices';

  export default {
    name: "AddVehicle",
    components: {
      AllVehicles,
      Vehicles
    },
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      dateWork: null,
      dialog: true,
      item: null,
      loadingConfirm: false,
      loadingAll: false,
      loadingRefs: false,
      tab: 0,
      vehicles: [],
      allVehicles: [],
      routes: [],
      compositions: [],
      contracts: [],
      certificates: [],
      startContractDt: null,
      endContractDt: null,
      composition: null,
      route: null,
      carrierId: null,
      contractId: null,
    }),
    watch: {
      composition: function(){
        this.getVehiclesList();
      }
    },
    created() {
      this.item = this.collection.master.selected;
      this.startContractDt = this.item.vccarriercontractsStartcontractdt;
      this.endContractDt = this.item.vccarriercontractsEndcontractdt;
      this.carrierId = this.item.vccarriercontractsCarrierid;
      this.contractId = this.item.vccarriercontractsId;

      this.dateWork = $utils.formatDate(
        new Date(Date.now()),
        'YYYY-MM-DD HH:mm:ss',
      );

      this.loadingRefs = true;
      this.getRoutes();
      this.loadingRefs = false;
    },
    methods: {
      getCurrentComposition(composition){
        this.composition = composition;
      },
      getCurrentRoute(route){
        this.route = route;
      },
      onClose() {
        this.dialog = !this.dialog;
      },
      cancelAdd() {
        this.tab = 0;
      },
      showAllVehicles() {
        this.tab = 1;
      },
      onAdd(items) {
        this.tab = 0;

        items.forEach(item => {
          const index = this.vehicles.findIndex(vehicle => {
            return vehicle.id === item.id;
          });

          if (index === -1) {
            this.vehicles.push(item);
          }
        });
      },
      removeVehicle(item) {
        this.vehicles = this.vehicles.filter(vehicle => vehicle.id !== item.id)
      },
      confirmAdd() {
        this.loadingConfirm = true;
        this.addVehicle(this.vehicles);

      },
      async getCompositions(routeId) {
        const requestShedules = {
          type: 'core-read',
          query: 'sin2:/v:311d5ebc-c087-4a32-8b7a-a614b7f40026'
        };

        const resp = await jet.http.post(requestShedules);
        if (!resp.error) {
          return resp.result;
        }
      },
      async getVehicles(carrierId, startContractDt) {

        const startDtMillis = $utils.formatDate(
          new Date(startContractDt),
          'YYYY-MM-DD HH:mm:ss',
        );

        const requestVehicles = {
          type: 'core-read',
          query: `sin2:/v:49943a80-98b4-480c-8700-9d91a57f7630?filter=and(or(isnull(param("${carrierId}","id")),eq(field(".crrID"),param("${carrierId}","id")),exists("883513b6-7c41-45e4-baa2-ab55bbf7d2cf",(r){and(or(eq(r.field(".carrierid"),param("${carrierId}","id")),eq(r.field(".subcarrierid"),param("${carrierId}","id"))),or(eq(r.field(".carrierid"),field(".crrid")),eq(r.field(".subcarrierid"),field(".crrid"))))})),or(isnull(field(".endDt")),gte(field(".endDt"),var("${startDtMillis}","date"))))`,
        };

        try {
          const resp = await jet.http.post(requestVehicles);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список ТС',
            color: 'warning'
          });
          console.error('AddVehicle: getVehicles()', e);
          return;
        }

      },
      async getRoutesByContracts(carrierContractId) {
        const routesQuery = {
          type: 'core-read',
          query: `sin2:/v:abf7cb2d-c5b7-463c-86c8-fb63c7502718?filter=and(
                    exists(\"ffd7d02e-81e4-48bf-8066-c384c9385a84\",
                    \"and(eq(field(\\\".contractID\\\"),
                    param(\\\"${carrierContractId}\\\", \\\"id\\\")),
                    eq(super.field(\\\".ID\\\"), field(\\\".routeID\\\")))\"),
                    eq(field(\".route.isActiveRoute\"),param(true,\"boolean\")),
                    exists(\"ffd7d02e-81e4-48bf-8066-c384c9385a84\",
                    \"and(eq(field(\\\".contractID\\\"),
                    param(\\\"${carrierContractId}\\\", \\\"id\\\")),
                    eq(super.field(\\\".ID\\\"),field(\\\".routeID\\\")),
                    or(isnull(super.field(\\\".route.endDt\\\")),
                    gte(super.field(\\\".route.endDt\\\"),field(\\\".startContractDt\\\"))))\"))`
        }

        try {
          const resp = await jet.http.post(routesQuery);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список маршрутов',
            color: 'warning'
          });
          console.error('AddVehicle: getRoutesByContracts()',e);
          return;
        }
      },
      async getRoutes(){
        let routes = [];
        let comp = [];
        let routeName = '';
        let routeId = '';
        const routesByContracts = await this.getRoutesByContracts(this.contractId);
        const routesData = (routesByContracts && routesByContracts.data) || [];
        await Promise.all(routesData.map(async it => {
          routeName = it[1] + ' ' + it[2];
          routeId = it[8];
          routes.push({
            id: routeId,
            name: routeName,
          });
        }));

        const compositions = await this.getCompositions();
        const compData = (compositions && compositions.data) || [];
        await Promise.all(compData.map(async it => {
          comp.push({
            id: it[4],
            name: it[3],
          });
        }));

        this.compositions = comp;
        this.routes = routes;
      },
      async getVehiclesList() {
        const vehiclesList = await this.getVehicles(this.carrierId, this.startContractDt);
        const vehiclesData = (vehiclesList && vehiclesList.data) || [];

        this.allVehicles = vehiclesData.map(it => ({
            id: it[5],
            govNum: it[1],
            code: it[0],
            routeId: this.route.id,
            startDate: GeneralServices.formatDate(this.startContractDt),
            endDate: GeneralServices.formatDate(this.endContractDt),
            compositionId: this.composition.id,
            compositionName: this.composition.name,
            routeName: this.route.name,
        }));

        //console.log(this.allVehicles);
      },
      async addVehicle(paramsArray) {
        let docNum = '';
        let compositionId = '';
        let id = '';
        let vehicleIds = [];

        const data = paramsArray || [];
        data.forEach(it => {
          vehicleIds.push(it.id)
        });

        let {id: userId, tenant: tenantId} = this.$store.state.profile;
        const optionsQuery = {
          type: "query",
          query:"d16cb8ba-8ffd-4753-89bc-5c7499bfa500.insertvehiclesintocontract",
          params: {
            servicedrouteid: this.route.id,
            vehicleids: vehicleIds.toString(),
            contractid: this.contractId,
            tenantid: tenantId, //this.item._sec_attrsTenantid,
            userid: userId,     //this.item._sec_attrsUserid,
            startdt: this.startContractDt,
            enddt: this.endContractDt,
            vehiclecompositionid: this.composition.id,
          },
        };

        try {
          let resp = await jet.http.post(optionsQuery);
          if (resp.error) {
            throw resp.error;
          }
          jet.msg({
            text: `Добавление ТС в договор № ${this.item.vccarriercontractsDocnum} выполнено успешно!`,
            type: 'warning'
          });
        } catch (e) {
          jet.msg({
            text: "Не удалось добавить ТС",
            color: "warning",
          });
          console.error("AddRoute: addRoute()", e);
          return;
        }
        this.dialog = false;
        this.collection.refresh();
      }
    }
  }
</script>

